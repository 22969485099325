#root {
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
  width: 100%;
}

@keyframes navbar-animation {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.animate-navbar-animation {
  animation: navbar-animation 0.3s ease forwards;
}

.carousel {
  position: relative;
  overflow: hidden;
  width: 100%;
}

.slide {
  display: flex;
  transition: transform 0.5s ease-in-out;
}

.prev,
.next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.prev {
  left: 0;
}

.next {
  right: 0;
}
